import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  ModalOrderView,
  ModalTrackingView,
  OrderExcel,
  TrackingExcel,
} from 'components/OrderCollection';
import { Button, Upload, Spin } from 'antd';
import LimitedEventView from '../components/EventHistory/PromotionView';
import Swal from 'sweetalert2';
import { ExcelRenderer } from 'react-excel-renderer';
import {
  gatherSabangnet,
  mapEvent,
  getLimitedEventData,
  trackingNumber,
  getOrderData,
} from 'http-api';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

const { Option } = Select;
registerLocale('ko', ko);

function OrderCollection() {
  const user = useSelector((state) => state.user);
  const [modalOrderVisible, setModalOrderVisible] = useState(false);
  const [modalTrackingVisible, setModalTrackingVisible] = useState(false);
  const [isOrderConfirm, setIsOrderConfirm] = useState(false); // Excel 다운로드 Yes or No
  const [isTrackingConfirm, setIsTrackingConfirm] = useState(false); // Excel 다운로드 Yes or No
  const [step, setStep] = useState(0);

  const [orderExcelData, setOrderExcelData] = useState([]);
  const [trackingExcelData, setTrackingExcelData] = useState([]);
  const [progressStep, setProgressStep] = useState(0);

  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('all');

  const now = new Date();

  const openOrderModal = () => {
    setModalOrderVisible(true);
  };
  const openTrackingModal = () => {
    setModalTrackingVisible(true);
  };
  const closeOrderModal = () => {
    setModalOrderVisible(false);
    setProgressStep(0);
    setStep(0);
  };
  const closeTrackingModal = () => {
    setModalTrackingVisible(false);
    setProgressStep(0);
    setStep(0);
  };

  const onClickOrderDownload = async () => {
    setDownloadLoading(true);
    try {
      const res = await getOrderData(user.token, {
        data: selectedDate,
        type: selectedType,
      });
      if (res.status === 201) {
        if (res.data.result.length !== 0) {
          setOrderExcelData(res.data.result);
          setIsOrderConfirm(true);
        }
      }
    } catch (err) {
      if (err.response.status === 400) {
        Swal.fire({
          icon: 'warning',
          text: '해당 날짜의 주문서 정보가 없습니다.',
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
    setDownloadLoading(false);
  };

  const gatherSabangnetOrder = async () => {
    setStep(1);
    setProgressStep(2);
    const res = await gatherSabangnet(user.token);
    if (res.status === 200) {
      setProgressStep(3);
      eventMapping(res.data.result);
    }
  };

  const eventMapping = async (data) => {
    const res = await mapEvent(user.token, data);
    if (res) {
      setOrderExcelData(res.data.result);
      setProgressStep(4);
    }
  };

  const getEventData = async () => {
    try {
      const res = await getLimitedEventData(user.token);
      if (res.length !== 0) {
        setEventData(res);
        setLoading(false);
      } else if (res.length === 0) {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      alert('데이터를 불러올 수 없습니다.');
      setLoading(false);
    }
  };
  const uploadExcel = () => {
    Swal.fire({
      text: 'Excel 파일을 등록 하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        setStep(1)
        document.getElementById('upload').click();
      }
    });
  };
  const fileHandler = (fileList) => {
    let fileObj = fileList;
    if (!fileObj) {
      alert('파일이 업로드되지 않았습니다.');
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        console.log(resp);
        resp.rows.map((row, index) => {
          if (row && row !== 'undefined') {
            newRows.push({
              key: index,
              receiver: row[0],
              phone1: row[1],
              phone2: row[2],
              zip: row[3],
              address: row[4],
              quantity: row[5],
              item: row[6],
              message: row[7],
              channel: row[8],
              orderNumber: row[9],
              smartId: row[10],
              no: row[11],
              trackingNumber: row[12],
              checkedName: row[13],
            });
          }
          return newRows;
        });
        if (newRows.length === 0) {
          // setErrorMessage('No data found in file!');
          return false;
        } else {
          postTrackingMap(newRows);
        }
      }
    });
    return false;
  };
  const postTrackingMap = async (excelData) => {
    try {
      const res = await trackingNumber(user.token, excelData);
      if (res.status === 201) {
        setTrackingExcelData(res.data.result);
        setStep(1);
        setProgressStep(3);
      } else {
        alert('데이터를 등록해주세요');
      }
    } catch (err) {
      alert('데이터를 불러올 수 없습니다.');
    }
  };
  function handleChange(value) {
    setSelectedType(value);
  }

  useEffect(() => {
    if (user.token) {
      getEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);
  useEffect(() => {
    if (selectedDate.getDate() !== now.getDate()) {
      setSelectedType('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <Container>
      <ButtonWrapper>
        <Description>
          현재 진행중인 프로모션을 반영해서 주문서를 변환합니다.
        </Description>
        <ExcelButtonWrapper>
          <ExcelButton onClick={() => openOrderModal()}>
            주문서 변환
          </ExcelButton>
          {isOrderConfirm && (
            <OrderExcel
              data={orderExcelData}
              setIsConfirm={() => {
                setIsOrderConfirm(false);
              }}
              fileName={selectedDate.toISOString().substring(0, 10) + ' 주문서'}
            />
          )}
          <ExcelButton onClick={() => openTrackingModal()}>
            송장 번호 등록
          </ExcelButton>
          <Upload
            roll="upload"
            name="file"
            id="upload"
            accept=".xlsx, .xls"
            beforeUpload={fileHandler}
            // onRemove={() => setRows([])}
            multiple={false}
          >
            <button style={{ display: 'none' }}>업로드</button>
          </Upload>
          {isTrackingConfirm && (
            <TrackingExcel
              data={trackingExcelData}
              setIsConfirm={() => {
                setIsTrackingConfirm(false);
              }}
              fileName={
                selectedDate.toISOString().substring(0, 10) + ' 변환된 주문서'
              }
            />
          )}
        </ExcelButtonWrapper>
        <CalenderWrapper>
          날짜선택 :
          <Calender
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            startDate={selectedDate}
            dateFormat={'yyyy. MM. dd'}
            maxDate={new Date()}
            locale={ko}
          />
          {selectedDate.getDate() === now.getDate() && (
            <Select
              defaultValue="all"
              style={{ width: 80, marginLeft: 30 }}
              onChange={handleChange}
            >
              <Option value="all">전체</Option>
              <Option value="am">오전</Option>
              {/* <Option value="disabled" disabled>
              Disabled
            </Option> */}
              <Option value="pm">오후</Option>
            </Select>
          )}
          <DownloadButton
            onClick={() => onClickOrderDownload()}
            loading={downloadLoading}
          >
            주문서 다운로드
          </DownloadButton>
        </CalenderWrapper>

        {modalOrderVisible && (
          <ModalOrderView
            gatherSabangnetOrder={gatherSabangnetOrder}
            closeModal={closeOrderModal}
            step={step}
            orderDownload={() => setIsOrderConfirm(true)}
            progressStep={progressStep}
            user={user}
          />
        )}
        {modalTrackingVisible && (
          <ModalTrackingView
            closeModal={closeTrackingModal}
            uploadExcel={uploadExcel}
            progressStep={progressStep}
            setIsTrackingConfirm={() => setIsTrackingConfirm(true)}
            step={step}
            user={user}
          />
        )}
        <ProgressText>
          <span>
            * 진행 과정 : 주문수집(사방넷) {' > '} 매핑(사방넷) {' > '}
            <b>주문서 변환(스마트물류)</b>
            {' > '} 택배사 업로드(한진) {' > '}
            택배사 다운로드(한진) {' > '} <b>송장 번호 등록(스마트물류)</b>
          </span>
        </ProgressText>
      </ButtonWrapper>

      <EventWrapper>
        <TitleWrapper>
          <SubTitle>진행중인 한정수량 이벤트</SubTitle>
          <CountTitle>
            (총 <BlueText>{eventData.length}</BlueText>건)
          </CountTitle>
        </TitleWrapper>
        {loading ? (
          <Spinner size="large" tip="데이터를 불러오는 중입니다..." />
        ) : (
          <LimitedEventView eventData={eventData} />
        )}
      </EventWrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  top: 0;
  left: 7%;
  width: 93%;
  height: calc(100% - 56px);
  text-align: center;
  /* z-index: -1; */
`;
const ButtonWrapper = styled.div`
  position: relative;
  top: 100px;
  display: inline-block;
  width: 80%;
  height: 20vh;
  text-align: start;
  padding-bottom: 40px;
`;

const Description = styled.div`
  position: relative;
  display: inline-block;
  color: ${(props) => props.theme.colors.darkGray};
`;

const ExcelButtonWrapper = styled.div`
  margin-top: 20px;
  .ant-upload-list {
    display: none;
  }
`;
const ExcelButton = styled(Button)`
  all: unset;
  margin-right: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  color: #000;
  background-color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.2s all;
  line-height: 23px;
  font-size: 18px;

  &:active {
    transform: translateY(3px);
  }
`;
// const InvoiceUploadButton = styled(Button)`
//   all: unset;
//   margin-left: 20px;
//   border: 1px solid ${(props) => props.theme.colors.gray};
//   color: #000;
//   background-color: #fff;
//   padding: 10px 15px;
//   cursor: pointer;
//   border-radius: 4px;
//   text-decoration: none;
//   transition: 0.2s all;
//   line-height: 23px;
//   font-size: 18px;

//   &:active {
//     transform: translateY(3px);
//   }
// `;
const ProgressText = styled.div`
  margin-top: 40px;
  color: ${(props) => props.theme.colors.darkGray};
`;
const EventWrapper = styled.div`
  margin-top: 80px;
  position: relative;
  top: 100px;
  display: inline-block;
  width: 80%;
  text-align: start;
  padding-bottom: 40px;
`;
const TitleWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
const SubTitle = styled.h2``;
const CountTitle = styled.h3`
  position: relative;
  margin-left: 20px;
  display: flex;
  direction: column;
`;
const BlueText = styled.div`
  margin-left: 5px;
  color: ${(props) => props.theme.colors.blue};
  font-weight: bold;
`;
const Spinner = styled(Spin)`
  margin-top: 200px;
`;
const CalenderWrapper = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  .react-datepicker-wrapper {
    display: inline-block;
    width: 100px;
  }
`;
const Calender = styled(DatePicker)`
  all: unset;
  margin-left: 10px;
  padding: 5px 10px;
  width: 80px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  cursor: pointer;
  &:hover {
    border-color: ${(props) => props.theme.colors.antBlue};
    color: ${(props) => props.theme.colors.antBlue};
  }
`;
const DownloadButton = styled(Button)`
  margin-left: 20px;
`;

export default OrderCollection;
