import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';

const AutoLogin = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;
`;
const ACheckbox = styled(Checkbox)`
  font-size: 0.8rem;
`;
const FindPasswordAndJoinWrap = styled.div`
  margin-top: 10px;
  float: right;
`;
const LinkMenu = styled(Link)`
  margin-left: 20px;
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  text-decoration: none;
`;

const AddLoginMenu = ({ autoLogin }) => (
  <div>
    <AutoLogin>
      <ACheckbox onChange={autoLogin}>자동로그인</ACheckbox>
    </AutoLogin>
    <FindPasswordAndJoinWrap>
      <LinkMenu to="/find-pw">비밀번호 찾기</LinkMenu>
      <LinkMenu to="/signup">회원가입</LinkMenu>
    </FindPasswordAndJoinWrap>
  </div>
);

export default AddLoginMenu;
