import React, { useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getBrandData } from 'http-api';

const BorderedButton = styled.button`
  all: unset;
  margin-top: 80px;
  position: relative;
  display: inline-block;
  width: 10rem;
  font-weight: 600;
  color: #fff;
  background-color: ${(props) => (props.disabled ? '#d9d9d9' : '#0077d7')};
  padding: 0.5rem;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s all;
`;

function NextButton({ clickedFreebie, clickedERP, user }) {
  const [pageUrl, setPageUrl] = useState('');

  async function onClick() {
    await checkUrl(clickedFreebie);
    document.getElementById('nextPage').click();
  }

  const checkUrl = async (clickedFreebie) => {
    try {
      if (clickedFreebie) {
        const result = await getBrandData(user.token);
        console.log(result);
        if (result.data.result.length === 0) {
          Swal.fire({
            icon: 'error',
            text: '[ERP 등록제품] 단계를 먼저 해주세요',
          }).then(() => (window.location.href = '/erp'));
        } else {
          setPageUrl('/freebie');
        }
      } else {
        setPageUrl('/erp');
      }
    } catch (err) {
      alert('잠시 후 다시 시도해주세요.');
    }
  };

  return (
    <>
      <BorderedButton
        disabled={!(clickedFreebie || clickedERP)}
        onClick={() => onClick(clickedFreebie)}
      >
        다음 단계로
      </BorderedButton>
      <Link to={pageUrl} aria-label="next">
        <div style={{ display: 'none' }} id="nextPage"></div>
      </Link>
    </>
  );
}

export default NextButton;
