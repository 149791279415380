import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import useForm from 'lib/useForm';
import { InputWithLabel } from '../components/Home';
import { postSabangnetData, getMyInfo } from 'http-api';
import validateSabangnet from '../lib/validateSabangnet';

export default function MyPage() {
  const [info, setInfo] = useState({});
  const tmpToken = localStorage.getItem('access_token');

  const asyncInfo = async () => {
    try {
      const infoData = await getMyInfo(tmpToken);
      setInfo(infoData);
    } catch (error) {
      setInfo({});
    }
  };

  useEffect(() => {
    asyncInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Body>
        <RightSection>
          <MyInfo info={info} asyncInfo={asyncInfo} />
        </RightSection>
      </Body>
    </Container>
  );
}

const Container = styled.div`
  margin-left: 150px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  min-height: 73vh;
`;

const RightSection = styled.section`
  margin-left: 3em;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InfoTitle = styled.div`
  display: flex;
  padding-left: 5px;
  padding-bottom: 3px;

  margin-top: 4em;
  margin-left: 3em;

  font-size: 1.5em;
  font-weight: 600;

  border-bottom: 2px solid gray;
`;

function MyInfo({ info, asyncInfo }) {
  const user = useSelector((state) => state.user);
  const [inputMode, setInputMode] = useState(0);
  const [teamInput, setTeamInput] = useState('');

  const { values, errors, submitting, handleChange, handleSubmit } = useForm({
    initialValues: { apiKey: '', apiId: '' },
    onSubmit: (values) => {
      postSabangnetDatas(values);
    },
    validate: validateSabangnet,
  });

  const postSabangnetDatas = async (row) => {
    console.log(row);
    try {
      const response = await postSabangnetData(row, user.token);
      if (response.status === 201) {
        console.log(response);
        // window.location.reload();
      } else alert('데이터를 등록해주세요.');
    } catch (err) {
      if (err.response.status === 403) {
        alert('그룹을 등록해주세요');
      } else {
        alert('데이터를 불러올 수 없습니다.');
      }
    }
  };

  const initInput = () => {
    setInputMode(0);
    setTeamInput('');
    window.location.reload();
  };

  function addTeam(token, body = {}) {
    if (!token) {
      throw new Error('no token');
    }

    return axios
      .post(`http://1.222.82.107:3001/group/`, body, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => res.data);
  }

  // body = { teamCode }
  function requestJoinTeam(token, body = {}) {
    if (!token) {
      throw new Error('no token');
    }

    return axios
      .post(`https://api2fulfillment.sellha.kr/request-join-team`, body, {
        headers: { authorization: token },
      })
      .then((res) => res.data);
  }
  function removeUserTeam(token, teamId, userId) {
    if (!token) {
      throw new Error('no token');
    }

    return axios
      .delete(
        `https://api2fulfillment.sellha.kr/team/${teamId}/user/${userId}`,
        {
          headers: { authorization: token },
        }
      )
      .then((res) => res.data);
  }

  return (
    <MyInfoDiv>
      <InfoTitle>내 정보</InfoTitle>
      <MyInfoContent>
        <LabelDiv>
          <NameDiv>이름</NameDiv>
          <UserDiv>{info.name}</UserDiv>
        </LabelDiv>
        <LabelDiv>
          <NameDiv>휴대폰번호</NameDiv>
          <UserDiv>{info.phone}</UserDiv>
        </LabelDiv>
        <LabelDiv>
          <NameDiv>이메일</NameDiv>
          <UserDiv>{info.email}</UserDiv>
        </LabelDiv>
        <LabelDiv>
          <NameDiv>비밀번호</NameDiv>
          <UserDiv>*********</UserDiv>
        </LabelDiv>
        {user.teamName && (
          <LabelDiv>
            <NameDiv>계정 상태</NameDiv>
            <UserDiv>{info.teamName}팀 소속</UserDiv>
            <ButtonTeam
              onClick={() => {
                alert(info.teamCode);
              }}
            >
              팀 코드
            </ButtonTeam>
            <ButtonTeam
              onClick={() => {
                removeUserTeam(info.token, info.teamId, info.id)
                  .then(() => {
                    initInput();
                    alert('삭제되었습니다');
                  })
                  .catch(() => alert('삭제 실패'));
              }}
            >
              팀 삭제
            </ButtonTeam>
          </LabelDiv>
        )}
        {!info.teamId && (
          <LabelDiv>
            <NameDiv>계정 상태</NameDiv>
            {!inputMode && (
              <>
                <UserDiv>개인 계정</UserDiv>
                <ButtonTeam
                  onClick={() => {
                    setInputMode(1);
                  }}
                >
                  팀 생성
                </ButtonTeam>
                <ButtonTeam
                  onClick={() => {
                    setInputMode(2);
                  }}
                >
                  팀 등록
                </ButtonTeam>
              </>
            )}
            {inputMode !== 0 && (
              <>
                <Input
                  style={{ display: 'flex', flex: 0.2 }}
                  value={teamInput}
                  onChange={(e) => setTeamInput(e.target.value)}
                  placeholder={
                    inputMode === 1
                      ? '팀명을 입력하세요.'
                      : '팀 코드를 입력하세요.'
                  }
                />
                <ButtonTeam
                  onClick={() => {
                    if (inputMode === 1) {
                      addTeam(user.token, { name: teamInput, code: user.code })
                        .then(() => {
                          alert('팀이 생성되었습니다.');
                          initInput();
                          asyncInfo();
                        })
                        .catch(() => {
                          alert('생성 실패');
                        });
                    } else if (inputMode === 2) {
                      requestJoinTeam(user.token, { code: teamInput })
                        .then(() => {
                          alert('팀이 등록되었습니다.');
                          initInput();
                          asyncInfo();
                        })
                        .catch(() => {
                          alert('등록 실패');
                        });
                    }
                  }}
                >
                  확인
                </ButtonTeam>
                <ButtonTeam
                  onClick={() => {
                    initInput();
                  }}
                >
                  취소
                </ButtonTeam>
              </>
            )}
          </LabelDiv>
        )}
      </MyInfoContent>
      <InfoTitle>주문수집 사이트 ID & Key</InfoTitle>
      <MyInfoContent>
        {(info.group && info.group.sabangnet) !== null ? (
          <>
            <LabelDiv>
              <NameDiv>주문수집 사이트 ID</NameDiv>
              <UserDiv>{info.group?.sabangnet.apiId}</UserDiv>
            </LabelDiv>
            <LabelDiv>
              <NameDiv>주문수집 사이트 Key</NameDiv>
              <UserDiv>{info.group?.sabangnet.apiKey}</UserDiv>
            </LabelDiv>
          </>
        ) : (
          <LabelDiv>
            <form onSubmit={handleSubmit} noValidate>
              <InputWithLabel
                type="text"
                label="주문수집 사이트 ID"
                name="apiId"
                placeholder="주문수집 사이트 ID를 입력하세요"
                value={values.apiId}
                onChange={handleChange}
              />
              {errors.apiId && (
                <span className="errorMessage">{errors.apiId}</span>
              )}
              <InputWithLabel
                type="text"
                label="주문수집 사이트 Key"
                name="apiKey"
                placeholder="주문수집 사이트 키를 입력하세요"
                value={values.apiKey}
                onChange={handleChange}
              />
              {errors.apiKey && (
                <span className="errorMessage">{errors.apiKey}</span>
              )}

              <button
                style={{ display: 'none' }}
                id="submit"
                type="submit"
                disabled={submitting}
                aria-label="regist"
              ></button>
              <CompleteButtonLabel disabled={submitting} htmlFor="submit">
                등록
              </CompleteButtonLabel>
            </form>
          </LabelDiv>
        )}
      </MyInfoContent>
    </MyInfoDiv>
  );
}

const MyInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const MyInfoContent = styled.div`
  margin-left: 7em;
  margin-top: 1em;

  font-size: 1em;
`;

const LabelDiv = styled.div`
  display: flex;
  margin-top: 1em;
`;

const NameDiv = styled.div`
  width: 10em;
`;

const UserDiv = styled.div`
  margin-left: 1em;
`;

const ButtonTeam = styled(Button)`
  border-radius: 10px;
  margin-top: -3px;
  margin-left: 10px;
`;
const CompleteButtonLabel = styled.label`
  all: unset;
  margin: 20px auto;
  display: flex;
  color: #a1a1a1;
  background-color: #fff;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.darkGray};
  border-radius: 4px;
  text-decoration: none;
  font-size: 12px;
  justify-content: center;
  transition: 0.2s all;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
    border-color: ${(props) => props.theme.colors.blue};
  }
`;
