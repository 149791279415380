import React, { useState } from 'react';
import styled from 'styled-components';
import backgroundImg from '../assets/images/home_bg.png';
import dashboardImage from '../assets/images/dashboard.png';
import { Login } from 'components/Home';

function Home() {
  const isLogined = localStorage.getItem('access_token');
  const [IsAutoLogin, setIsAutoLogin] = useState(false);

  return (
    <Container>
      {!isLogined ? (
        <>
          <BackgroundImage isLogined={isLogined} />
          <Login IsAutoLogin={IsAutoLogin} setIsAutoLogin={setIsAutoLogin} />
        </>
      ) : (
        <DashboardImageWrapper>
          <DashboardImage />
        </DashboardImageWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 79px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  /* z-index: -1; */
`;
const BackgroundImage = styled.div`
  position: relative;
  display: inline-block;
  top: 1px;
  left: 0;
  width: ${(props) => (props.isLogined ? '100%' : '65%')};
  height: 100%;
  background: url(${backgroundImg});
  background-position: center;
  background-size: cover;
`;
const DashboardImageWrapper = styled.div`
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;
const DashboardImage = styled.div`
  width: 90%;
  height: 4000px;
  background-image: url(${dashboardImage});
  background-repeat: no-repeat;
  background-size: contain;
`;

export default Home;
