import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  ERPMainTable,
  FreebieMainTable,
  ExportExcelButton,
  FreebieExcel,
  ERPExcel,
} from 'components/ItemInquiry';
import Swal from 'sweetalert2';
import {
  getBrandData,
  getFreebieData,
  getPrintData,
  getItemGroups,
} from 'http-api';
import { useSelector } from 'react-redux';

function ItemInquiry() {
  const user = useSelector((state) => state.user);
  const [tabStatus, setTabStatus] = useState('freebie');
  const [isConfirm, setIsConfirm] = useState(false); // Excel 다운로드 Yes or No

  const [freebieData, setFreebieData] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [freebieAndPrintData, setFreebieAndPrintData] = useState([]);

  const [erpData, setErpData] = useState([]);
  const [freebieExcelData, setFreebieExcelData] = useState([]);
  const [erpExcelData, setErpExcelData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState({
    freebieAndPrint: true,
    erp: true,
  });

  const getFreebieDatas = async () => {
    try {
      const res = await getFreebieData(user.token);
      if (res.data) {
        setFreebieData(
          res.data.result.map((data) => {
            return { ...data, category: res.data.category };
          })
        );
      } else {
        console.log(res.status);
        alert('데이터를 등록해주세요');
      }
    } catch (err) {
      alert('데이터를 1 수 없습니다.');
    } finally {
      setLoading((prev) => ({ ...prev, freebieAndPrint: false }));
    }
  };
  const getPrintDatas = async () => {
    try {
      const res = await getPrintData(user.token);
      if (res.data.result) {
        setPrintData(
          res.data.result.map((data) => {
            return { ...data, category: res.data.category };
          })
        );
      }
    } catch (err) {
      alert('데이터를 2 수 없습니다.');
    }
  };

  const getBrandDatas = async () => {
    try {
      const res = await getBrandData(user.token);
      if (res.data) {
        setBrandData(res.data.result);
      }
    } catch (err) {
      alert('데이터를 3 수 없습니다.');
    }
  };
  const getErpDatas = async () => {
    try {
      const res = await getItemGroups(user.token);

      if (res.data) {
        setErpData(res.data.result);
        setLoading((prev) => ({ ...prev, erp: false }));
      } else {
        console.log(res.status);
        alert('데이터를 등록해주세요');
      }
    } catch (err) {
      alert('데이터를 4 수 없습니다.');
      setLoading((prev) => ({ ...prev, erp: false }));
    }
  };

  useEffect(() => {
    if (user.token) {
      getFreebieDatas();
      getPrintDatas();
      getErpDatas();
      getBrandDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setFreebieAndPrintData(
      [...freebieData, ...printData].map((data, index) => {
        return { ...data, key: index };
      })
    );
  }, [freebieData, printData]);

  useEffect(() => {
    if (tabStatus === 'freebie') {
      setIsConfirm(false);
    } else if (tabStatus === 'erp') {
      setTabStatus('erp');
      setIsConfirm(false);
    }
  }, [tabStatus]);

  useEffect(() => {
    function createExcelData(data, type) {
      let itemArray = [];
      for (let i = 0; i < data.length; i++) {
        let itemGroup = data[i];
        for (let j = 0; j < itemGroup.items.length; j++) {
          let item = itemGroup.items[j];
          let itemObject = {};
          itemObject.category = itemGroup.category;
          if (type === 'freebie') {
            itemObject.brands = itemGroup.brands
              .map((brand) => brand.name)
              .join(', ');
          } else if (type === 'erp') {
            itemObject.brand = itemGroup.brands;
          }

          itemObject.groupName = itemGroup.name;
          itemObject.code = itemGroup.code;
          itemObject.register = itemGroup.register;
          itemObject.optionName = item.name;
          itemObject.skuCode = item.code;
          itemArray.push(itemObject);
        }
      }
      return itemArray;
    }

    if (tabStatus === 'freebie') {
      setFreebieExcelData(createExcelData(freebieAndPrintData, 'freebie'));
    } else if (tabStatus === 'erp') {
      setErpExcelData(createExcelData(erpData, 'erp'));
    }
  }, [freebieAndPrintData, erpData, tabStatus]);

  const onClickExcel = () => {
    let status = tabStatus === 'freebie' ? '사은품 · 인쇄물' : 'ERP 등록제품';
    Swal.fire({
      text: status + ' Excel 파일을 다운로드 하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsConfirm(true);
      } else {
        setIsConfirm(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setLoading({ freebieAndPrintData: false, erp: false });
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <SubTitle>등록한 아이템 조회하기</SubTitle>
        <br />
        <Description>
          아이템 등록 페이지에 입력한 아이템 정보를 조회할 수 있습니다.
        </Description>
        <br />
        <FreebieAndPrintTab
          id="freebie"
          status={tabStatus}
          onClick={() => {
            setTabStatus('freebie');
          }}
        >
          사은품 · 인쇄물
        </FreebieAndPrintTab>
        <ErpTab
          id="erp"
          status={tabStatus}
          onClick={() => {
            setTabStatus('erp');
          }}
        >
          ERP 등록제품
        </ErpTab>
        <ExportExcelButton text="엑셀 파일 받기" onClick={onClickExcel} />
        {isConfirm && tabStatus === 'freebie' && (
          <FreebieExcel
            data={freebieExcelData}
            setIsConfirm={() => {
              setIsConfirm(false);
            }}
          />
        )}
        {isConfirm && tabStatus === 'erp' && (
          <ERPExcel
            data={erpExcelData}
            setIsConfirm={() => {
              setIsConfirm(false);
            }}
          />
        )}
        {tabStatus === 'freebie' && (
          <FreebieMainTable
            data={freebieAndPrintData}
            loading={loading.freebieAndPrint}
            brandData={brandData}
          />
        )}
        {tabStatus === 'erp' && (
          <ERPMainTable
            data={erpData}
            loading={loading.erp}
            brandData={brandData}
          />
        )}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  text-align: center;
  /* z-index: -1; */
`;
const Wrapper = styled.div`
  position: relative;
  top: 100px;
  display: inline-block;
  width: 60%;
  text-align: start;
  padding-bottom: 40px;
`;
const SubTitle = styled.h2`
  position: relative;
  display: inline-block;
`;
const Description = styled.div`
  position: relative;
  display: inline-block;
  color: ${(props) => props.theme.colors.darkGray};
`;
const FreebieAndPrintTab = styled.div`
  position: absolute;
  display: inline-block;
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.darkGray};
  border-bottom: 0;
  background-color: ${(props) =>
    props.status === 'freebie' ? '#f9fbff' : '#fff'};
  color: ${(props) => props.theme.colors.darkGray};
  line-height: 50px;
  text-align: center;
  z-index: 1;
  &:hover {
    font-weight: bold;
    color: #1a83ff;
    cursor: pointer;
  }
`;
const ErpTab = styled.div`
  position: absolute;
  display: inline-block;
  margin-top: 20px;
  left: 210px;
  width: 200px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.darkGray};
  border-bottom: 0;
  background-color: ${(props) => (props.status === 'erp' ? '#f9fbff' : '#fff')};
  color: ${(props) => props.theme.colors.darkGray};
  line-height: 50px;
  text-align: center;
  z-index: 1;
  &:hover {
    font-weight: bold;
    color: #1a83ff;
    cursor: pointer;
  }
`;

export default ItemInquiry;
