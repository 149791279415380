import { combineReducers } from 'redux';
import base from './modules/base';
import auth from './modules/auth';
import user from './modules/user';

export default combineReducers({
  base,
  auth,
  user,
});
