import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  PromotionView,
  PeriodView,
  SituationView,
} from 'components/EventHistory';
import { getEventHistory } from 'http-api';
import { Spin } from 'antd';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

function EventHistory() {
  const user = useSelector((state) => state.user);
  const [categoryValue, setCategoryValue] = useState('promotion');
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEventData = async () => {
    try {
      const res = await getEventHistory(user.token);
      if (res.status === 200) {
        if (res.data.result.length !== 0) {
          setEventData(res.data.result);
          setLoading(false);
        } else if (res.data.result.length === 0) {
          Swal.fire('등록된 이벤트가 없습니다.');
          setLoading(false);
        }
      }
    } catch (err) {
      alert('데이터를 불러올 수 없습니다.');
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user.token) {
      getEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <Container>
      <CategoryHeader>
        <Category
          className="first"
          onClick={() => setCategoryValue('promotion')}
          clickedCategory={categoryValue === 'promotion'}
        >
          프로모션별
        </Category>
        <Category
          onClick={() => setCategoryValue('period')}
          clickedCategory={categoryValue === 'period'}
        >
          기간별
        </Category>
        <Category
          onClick={() => setCategoryValue('situation')}
          clickedCategory={categoryValue === 'situation'}
        >
          현황별
        </Category>
      </CategoryHeader>
      {loading ? (
        <Spinner size="large" tip="데이터를 불러오는 중입니다..." />
      ) : (
        <Wrapper>
          {categoryValue === 'promotion' && (
            <PromotionView eventData={eventData} />
          )}
          {categoryValue === 'period' && <PeriodView eventData={eventData} />}
          {categoryValue === 'situation' && (
            <SituationView eventData={eventData} />
          )}
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  top: 0;
  left: 5%;
  width: 95%;
  height: calc(100% - 56px);
  text-align: center;
`;
const CategoryHeader = styled.div`
  position: sticky;
  top: 0;
  margin-left: -10px;
  width: calc(100% + 10px);
  height: 80px;
  text-align: start;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  background-color: #fff;
  z-index: 3;
`;
const Category = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
  height: 100%;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;

  color: ${(props) => (props.clickedCategory ? '#0077d7' : '#000')};
  border-bottom: ${(props) =>
    props.clickedCategory ? '3px solid #0077d7' : 'none'};
  &.first {
    margin-left: 200px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;
const Wrapper = styled.div`
  position: relative;
  top: 40px;
  display: inline-block;
  width: 80%;
  text-align: start;
  padding-bottom: 40px;
`;
const Spinner = styled(Spin)`
  margin-top: 80px;
`;

export default EventHistory;
