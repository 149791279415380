import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { updateFreebieData, createFreebieData } from 'http-api';

const BorderedButton = styled(Button)`
  all: unset;
  margin: 0 0 0 20px;
  position: relative;
  display: inline-block;
  font-weight: 600;
  width: 100px;
  text-align: center;
  color: #fff;
  border: 1px solid ${(props) => props.theme.colors.gray};
  background-color: ${(props) => (props.disabled ? '#d9d9d9' : '#0077d7')};
  padding: 0.5rem;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s all;
`;

function CompleteButton(props) {
  const user = props.user;
  const updateData = async (row, category) => {
    props.setLoading(true);

    const url =
      category.split('/')[0] +
      '/' +
      props.dataKey +
      '/' +
      category.split('/')[1];

    const data = {
      groupId: 1,
      data: row,
    };

    try {
      const res = await updateFreebieData(user.token, url, data);
      if (res.status === 200) {
        console.log(res.data);
        props.enterLoading();
      } else {
        alert('데이터를 등록해주세요');
      }
    } catch (err) {
      alert('데이터를 불러올 수 없습니다.');
    }
    props.setLoading(false);
  };
  const createData = async (row, category) => {
    console.log(category);
    props.setLoading(true);
    const data = {
      groupId: 1,
      data: row,
    };
    console.log(row);

    try {
      const res = await createFreebieData(user.token, category, data);
      if (res.status === 201) {
        console.log(res.data);
        props.enterLoading();
      } else {
        console.log(res.status);
        alert('데이터를 등록해주세요');
      }
    } catch (err) {
      alert('데이터를 불러올 수 없습니다.');
    }
    props.setLoading(false);
  };
  function onClick() {
    console.log(props.category);
    if (!props.title) {
      alert('사은품 또는 인쇄물의 이름을 입력해주세요');
      return false;
    } else if (!props.category) {
      alert('분류를 선택해주세요');
      return false;
    } else if (!props.brand.length) {
      alert('브랜드를 선택해주세요');
      return false;
    } else if (!props.options.length) {
      alert('하나 이상의 옵션을 추가해주세요');
      return false;
    } else {
      for (let i = 0; i < props.options.length; i++) {
        let option = props.options[i];
        if (!option.image) {
          alert('옵션의 이미지를 등록해주세요');
          return false;
        }
        if (!option.name) {
          alert('옵션의 옵션명을 입력해주세요');
          return false;
        }
      }
    }

    let jsonData = {};
    jsonData.name = props.title;
    // jsonData.category = props.category;
    jsonData.brands = props.brand.map((brand) => brand.value);
    jsonData.items = props.options;
    jsonData.key = props.key;
    if (props.text === '등록') {
      createData(jsonData, props.category.value);
    } else {
      updateData(jsonData, props.category.value);
    }
  }
  return (
    <BorderedButton
      type="primary"
      loading={props.loading}
      onClick={() => onClick()}
    >
      {props.loading ? '' : props.text}
    </BorderedButton>
  );
}

export default CompleteButton;
