import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { checkVerify, checkRefresh, getMyInfo } from 'http-api';
import { login, setUserInfo } from 'redux/modules/user';
import Navigator from 'components/Base/SideMenu/Navigator';
import './App.css';
import { EventHistory, ERP } from './pages';
import Swal from 'sweetalert2';

const Home = lazy(() => import('./pages/Home'));
const ItemRegistration = lazy(() => import('./pages/ItemRegistration'));
const FreebieAndPrint = lazy(() => import('./pages/FreebieAndPrint'));
// const ERP = lazy(() => import('./pages/ERP'));
const ItemInquiry = lazy(() => import('./pages/ItemInquiry'));
const EventRegistration = lazy(() => import('./pages/EventRegistration'));
// const EventHistory = lazy(() => import('./pages/EventHistory'));
const OrderCollection = lazy(() => import('./pages/OrderCollection'));
const SignUp = lazy(() => import('./pages/SignUp'));
const ActivationAccount = lazy(() => import('./pages/ActivationAccount'));
const FindPassword = lazy(() => import('./pages/FindPassword'));
const MyPage = lazy(() => import('./pages/MyPage'));
const ChannelRegistration = lazy(() => import('./pages/ChannelRegistration'));
const EventGrouping = lazy(() => import('./pages/EventGrouping'));

function LoginRoute() {
  // const user = useSelector((state) => state.user);
  // 사용중인 경로인지 체크
  const match = useRouteMatch([
    '/registitem',
    '/freebie',
    '/erp',
    '/inquiryitem',
    '/registevent',
    '/productname',
    '/historyevent',
    '/support',
    '/mypage',
    '/registchannel',
    '/groupingevent',
  ]);

  return (
    <Switch>
      {match && match.isExact && !localStorage.getItem('access_token') && (
        <Redirect
          to="/"
          render={alert(
            '🔐 로그인이 필요한 페이지 입니다. 로그인 화면으로 이동합니다.'
          )}
        />
      )}
      <Route path="/registitem" component={ItemRegistration} />
      <Route path="/freebie" component={FreebieAndPrint} />
      <Route path="/erp" component={ERP} />
      <Route path="/inquiryitem" component={ItemInquiry} />
      <Route path="/registevent" component={EventRegistration} />
      <Route path="/historyevent" component={EventHistory} />
      <Route path="/support" component={OrderCollection} />
      <Route path="/registchannel" component={ChannelRegistration} />
      <Route path="/mypage" component={MyPage} />
      <Route path="/groupingevent" component={EventGrouping} />
      <Redirect to="/" />
    </Switch>
  );
}

function App() {
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user);
  // console.log(user);
  const tmpToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  // const email = { email: localStorage.getItem('email') };

  useEffect(() => {
    const bootstrap = async () => {
      try {
        if (!refreshToken) {
          return;
        }
        // const { accessToken } = await checkRefresh(refreshToken);
        // console.log(accessToken);
        if (tmpToken) {
          const res = await checkVerify(tmpToken);
          const info = await getMyInfo(tmpToken);
          // console.log(res);

          if (res.status === 200) {
            dispatch(setUserInfo(info));
            dispatch(login(tmpToken));
          }
        }
      } catch (err) {
        if (err.response?.status === 401) {
          try {
            const res = await checkRefresh(refreshToken);
            if (res.status === 200) {
              console.log('success_checkRefresh', res);
              localStorage.setItem('access_token', res.data.accessToken);
              // window.location.reload();
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: '로그인에 실패하습니다',
              text: '새로 로그인 해주세요',
              confirmButtonColor: '#0077d7',
            }).then((result) => {
              console.log(result);
              if (result.isConfirmed) {
                localStorage.removeItem('access_token');
                window.location.reload();
              }
            });
          }
        }
      }
    };
    bootstrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Navigator />
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/signup" component={SignUp} />
          <Route path="/activation" component={ActivationAccount} />
          <Route path="/find-pw" component={FindPassword} />

          <LoginRoute />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
