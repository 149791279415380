import axios from 'axios';

let HOST = 'https://api2fulfillment.sellha.kr';
let TEST_HOST = 'http://52.78.164.171:3001';

// if (process.env.REACT_APP_URL === 'production') {
//   HOST = 'https://api2fulfillment.sellha.kr/'; // master 서버
// }

export { HOST };

export async function checkDuplicateEmail(email) {
  const { data } = await axios.get(`${TEST_HOST}/signup/emailcheck/`, {
    params: { email },
  });
  return !!data.isDuplicated;
}
export async function checkDuplicatePhone(phone) {
  const { data } = await axios.get(`${TEST_HOST}/signup/phonecheck`, {
    params: { phone },
  });
  return !!data.isDuplicated;
}
export async function localSignup(signupForm) {
  const res = await axios.post(`${TEST_HOST}/signup/`, signupForm);
  return res.status;
}
// export function checkVerify(token) {
//   return axios.post(`${HOST}/auth/jwt/verify/`, token);
// }
// export function checkRefresh(token) {
//   return axios.post(`${HOST}/auth/jwt/refresh/`, token);
// }
// export async function createJwt(signupForm) {
//   return axios.post(`${HOST}/auth/jwt/create/`, signupForm);
// }
export async function sendFindPassword(name, email) {
  const res = await axios.post(`${HOST}/send-find-password/`, { name, email });
  return res.data;
}
// 로그인 관련 테스트 API
export async function createJwt(signupForm) {
  return axios.post(`${TEST_HOST}/signin/`, signupForm);
}
export function checkVerify(token) {
  return axios.get(`${TEST_HOST}/auth/jwt/verify/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export function checkRefresh(token) {
  return axios.get(`${TEST_HOST}/auth/jwt/refresh/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

// Page ERP
export async function getBrandData(token) {
  return axios.get(`${TEST_HOST}/brand/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export async function createItemgroups(token, data) {
  return axios
    .post(`${TEST_HOST}/ecount/`, data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((res) => res);
}
export async function updateItemGroups(token, data) {
  return axios
    .post(`${TEST_HOST}/ecount/`, data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((res) => res);
}
export async function getItemGroups(token) {
  return axios.get(`${TEST_HOST}/item-group/items/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export async function updateItemgroupsImage(token, data) {
  return axios.patch(`${TEST_HOST}/item-group/items/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

// Page OrderCollection
export function postSabangnetData(data, token) {
  return axios.post(`${TEST_HOST}/group/sabangnet/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export async function checkSabangnet(token) {
  const res = await axios.get(`${TEST_HOST}/order/check-sabangnet/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res.data.result;
}
export function gatherSabangnet(token) {
  return axios.get(`${TEST_HOST}/order/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export function mapEvent(token, data) {
  return axios.post(`${TEST_HOST}/order/map-event/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export async function getLimitedEventData(token) {
  const res = await axios.get(`${TEST_HOST}/event/limited/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res.data.result;
}
export async function trackingNumber(token, data) {
  const res = await axios.post(`${TEST_HOST}/order/tracking-number/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res;
}
export async function getOrderData(token, data) {
  const res = await axios.post(`${TEST_HOST}/order/download/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res;
}

// Page MyPage
export async function getMyInfo(token) {
  const res = await axios.get(`${TEST_HOST}/user/profile/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res.data.result;
}

// Page ChannelRegistration
export async function getSabangnetChannelList(token) {
  const res = await axios.get(`${TEST_HOST}/channel/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res.data.result;
}

export function createUserChannel(token, data) {
  return axios.post(`${TEST_HOST}/user-channel`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

export async function getUserChannel(token) {
  const res = await axios.get(`${TEST_HOST}/user-channel/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res.data.result;
}

export function deleteUserChannel(token, data) {
  return axios.delete(`${TEST_HOST}/user-channel/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: {
      data: data,
    },
  });
}

export function registSabangnetChannel(token) {
  return axios.get(`${TEST_HOST}/channel/sabangnet/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

// Page EventHistory
export function getEventHistory(token) {
  return axios.get(`${TEST_HOST}/event/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

// Page FreebieAndPrint
export function createFreebieData(token, url, data) {
  return axios.post(`${TEST_HOST}/${url}/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

export function updateFreebieData(token, url, data) {
  return axios.patch(`${TEST_HOST}/${url}/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getFreebieData(token) {
  return axios.get(`${TEST_HOST}/freebie-group/freebies/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

export function getPrintData(token) {
  return axios.get(`${TEST_HOST}/print-group/prints/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

// Page EventRegistration

export function getFreebieErpData(token) {
  return axios.get(`${TEST_HOST}/freebie-group/freebies/erp/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export async function getItemGroupsBrand(token) {
  return axios
    .get(`${TEST_HOST}/brand/item-groups/items/`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((res) => res);
}
export function getEventData() {
  return axios.get(`${HOST}/event/`);
}
export function registEventData(token, data) {
  return axios.post(`${TEST_HOST}/event/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export function getSelectedEventData(eventId) {
  return axios.get(`${HOST}/event/${eventId}/`);
}
export async function getEventChannel(token) {
  const res = await axios.get(`${TEST_HOST}/user-channel/event/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  return res.data.result;
}

// Page EventGrouping
export function getEventGroup(token) {
  return axios.get(`${TEST_HOST}/event-group/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export function createEventGroup(token, data) {
  return axios.post(`${TEST_HOST}/event-group/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export function deleteEventGroup(token, data) {
  return axios.delete(`${TEST_HOST}/event-group/`, {
    data: data,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export function getEventHistoryTest(token) {
  return axios.get(`${TEST_HOST}/event/sort/`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
