import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import s3Config from 'config/index';
import S3 from 'react-aws-s3';

function SubTable({ record, index, sendData, setSendData }) {
  const [items, setItems] = useState(record.items);
  const ReactS3Client = new S3(s3Config);
  useEffect(() => {
    console.log('sendData -------->', sendData);
  }, [sendData]);

  const data = [];
  for (let i = 0; i < items.length; i++) {
    data.push({
      key: items[i].id,
      name: items[i].name,
      code: items[i].code,
      image: items[i].image,
      mainImage: items[i].mainImage,
    });
  }

  const columns = [
    {
      title: '이미지',
      key: 'image',
      render: (option) => (
        <OptionTwoWrapper>
          <input
            type="file"
            accept="image/jpg, image/png, image/jpeg"
            id={'markImage' + index + option.key}
            name="image"
            onChange={(e) => {
              handleFileOnChange(e, option.key);
            }}
            style={{ display: 'none' }}
          ></input>
          {option.mainImage && <Mark>대표</Mark>}
          <AddOptionImage
            className={option.mainImage && 'mark'}
            htmlFor={'markImage' + index + option.key}
          >
            {option.image ? (
              <ItemPreviewImage className="full-image" src={option.image} />
            ) : (
              <Register>등록</Register>
            )}
          </AddOptionImage>
        </OptionTwoWrapper>
      ),
    },
    {
      title: 'SKU명',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SKU코드',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: '대표',
      key: 'mainImage',
      render: (item) => (
        <input
          type="checkbox"
          name={'mainImage' + index}
          id={'mainImage' + index}
          checked={item.mainImage}
          onChange={(e) => {
            handleRadioChange(e, item.key, item);
          }}
        />
      ),
    },
  ];

  async function handleFileOnChange(event, id) {
    try {
      const file = event.target.files[0];
      let uploadResult;

      if (file) {
        if (file.size >= 1 * 1024 * 1024) {
          alert('1mb 이하의 파일만 업로드 가능합니다.');
        } else {
          if (
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/jpg'
          ) {
            uploadResult = await ReactS3Client.uploadFile(file, file.name);
            console.log(uploadResult);
            setItems(
              items.map((item) => {
                if (item.id !== id) {
                  return item;
                } else {
                  item.image = uploadResult.location;
                  let imageUpdated = false;
                  setSendData(
                    sendData.map((data) => {
                      if (data.id === item.id) {
                        imageUpdated = true;
                        return item;
                      }
                      return data;
                    })
                  );
                  if (!imageUpdated) setSendData([...sendData, item]);
                  return { ...item };
                }
              })
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleRadioChange(e, id) {
    setItems(
      items.map((item) => {
        if (item.id !== id) {
          return { ...item, mainImage: false };
        } else {
          item.mainImage = true;
          let mainUpdated = false;
          setSendData(
            sendData.map((data) => {
              if (data.id === item.id) {
                mainUpdated = true;
                return item;
              } else {
                return { ...data, mainImage: false };
              }
            })
          );
          if (!mainUpdated) setSendData([...sendData, item]);
          return { ...item, mainImage: true };
        }
      })
    );
  }
  return (
    <SubTableSection columns={columns} dataSource={data} pagination={false} />
  );
}

const SubTableSection = styled(Table)`
  .ant-table-tbody > tr > td {
    font-weight: normal;
  }
`;
const OptionTwoWrapper = styled.div`
  display: inline-block;
  position: relative;
`;
const ItemPreviewImage = styled.img`
  position: relative;
  display: inline-block;
  border-radius: 5px;
  width: 60px;
  height: 60px;
  &.full-image {
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }
`;
const AddOptionImage = styled.label`
  all: unset;
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.darkGray};
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  &.mark {
    border: 2px solid ${(props) => props.theme.colors.blue};
  }
`;
const Register = styled.div`
  line-height: 80px;
`;
const Mark = styled.div`
  position: absolute;
  margin: 5px;
  width: 24px;
  background: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  z-index: 1;
`;

export default SubTable;
